<template>
  <div class="w-full">
    <div class="flex flex-col sm:flex-row items-center pt-5 border-b border-gray-200">
      <h2 class="font-medium text-base mr-auto">支付宝活动</h2>
      <div class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0">
      <button class="button mr-2 mb-2 flex items-center justify-center bg-theme-1 text-white">
        <RefreshCwIcon class="w-4 h-4 mr-2" />
        刷新
      </button>
    </div>
    </div>

    <!-- 数据 -->
    <div class="intro-y box p-5 w-full">
      <!-- 头部 查询表单 -->
      <div class="flex flex-col w-full sm:flex-row items-center mb-5 border-b border-gray-200">
        <el-form ref="formData" :model="formData" label-width="auto" size="small" style="width: 100%;">
          <div style="display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center;">
            <el-form-item label="活动类型" prop="activityType">
              <el-select v-model="formData.activityType"></el-select>
            </el-form-item>
            <el-form-item label="准入门槛" prop="doorSill">
              <el-input v-model="formData.doorSill"></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="state">
              <el-select v-model="formData.state"></el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm()">搜索</el-button>
              <el-button @click="resetForm()">重置</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <!-- 中部 数据 -->
      <el-table ref="tableData" :data="[{}, {}, {}, {}]" stripe @selection-change="handleSelectionChange">
        <el-table-column label="活动名称" align="left">
          <template>
            支付宝美食日
          </template>
        </el-table-column>
        <el-table-column label="活动类型" align="left">
          <template>
            这是一个类型
          </template>
        </el-table-column>
        <el-table-column label="活动状态" align="left">
          <template>
            活动未开始
          </template>
        </el-table-column>
        <el-table-column label="活动简介" align="left">
          <template>
            活动商品的支付方式仅限支付宝,在活动期间可享
            超大优惠
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left">
          <template>
            <span style="color: #327CFC; cursor: pointer;" @click="openDetailDialog(scope)">查看详情/申请</span>
          </template>
        </el-table-column>
      </el-table>

      <!-- 底部 分页 -->
      <div class="border-t border-gray-200 pt-5 flex mt-5">
        <el-pagination
            :current-page="currentPage"
            :page-sizes="[5, 10, 15, 20, 30, 50, 100, 200]"
            :page-size="limit"
            layout="total, prev, pager, next, sizes, jumper"
            :total="totalCount"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>

    <!-- 查看详情的接口 -->
    <DetailDialog :show-dialog="isDetailDialogVisible"
                  @handle-close="isDetailDialogVisible = false"
    ></DetailDialog>

  </div>
</template>

<script>

import DetailDialog from './components/detail-dialog'
/**
 * 活动申请
 */
export default {
  components: { DetailDialog },
  data() {
    return {
      isShowDialog: false,
      isDetailDialogVisible: false,
      detailDialogSelectItem: {},
      // 表单数据
      formData: {
        activityType: '',
        doorSill: '',
        state: ''
      },
      // 分页数据
      totalCount: 0,
      currentPage: 1,
      limit: 20,
      // 表格数据
      tableData: []
    }
  },
  created() {
    // 初始化
    this._fetchData()
  },
  methods: {
    openDetailDialog() {
      console.log('打开dialog')
      this.isDetailDialogVisible = true
    },
    // 刷新
    refreshData() {
      this._fetchData(true)
    },
    // 表格选中的数据
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // limit 改变
    handleSizeChange(val) {
      this.limit = val
      this._fetchData()
    },
    // 当前页面改变
    handleCurrentChange(val) {
      this.currentPage = val
      this._fetchData()
    },
    // 重置表单
    resetForm() {
      this.$refs.formData.resetFields()
      this._fetchData()
    },
    // 提交表单
     submitForm() {
       this._fetchData()
     },
    // 基础查询
    _fetchData(toast = false) {
      if (toast) this.$message.success('刷新成功～')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
<template>
  <!-- dialog -->
  <Dialog :is-show="showDialog" title="查看详情/申请" dialog-width="50%" confirm-btn-text="申请" @handle-close="handleClose()" @handle-submit="submitForm()">
    <template slot="content">

    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/views/Dialog'

export default {
  components: { Dialog },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    selectItem: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handleClose() {
      this.$emit('handle-close')
    },
    handleSuccess() {
      this.$emit('handle-success')
    },
    // 提交接口
    submitForm() {
    }
  }
}
</script>

<style scoped>

</style>